<template>
  <v-main>
    <v-container>
      <h3 class="mb-5 mt-5">Create Lesson</h3>
      <section v-if="errored">
        <p>
          We're sorry, we're not able to retrieve this information at the
          moment, please try back later
        </p>
      </section>
      <v-btn @click="goBackToLessonList" class="mb-5"
        >Back to Lesson List</v-btn
      >
      <div v-if="loading">Loading...</div>

      <v-row>
        <v-col>
          <v-select
            outlined
            :items="lectureList"
            item-text="lectureTitle"
            item-value="id"
            label="Lecture List"
            v-model="lesson.lectureId"
            required
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="lesson.indexSort"
            placeholder="Lesson Sort Index"
            outlined
            type="number"
          >
            <template v-slot:label>
              <div>Lesson Index Sort</div>
            </template>
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            outlined
            v-model="lesson.minReadingTime"
            type="number"
            placeholder="Minimum Reading Time"
            ><template v-slot:label>
              <div>Minimum Reading Time</div>
            </template></v-text-field
          >
        </v-col>
        <v-col>
          <v-checkbox
            v-model="lesson.isImageOnly"
            label="Is Image Only"
            color="red"
            hide-details
            required
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-tabs
        v-model="languageTab"
        class="mb-5"
        background-color="deep-black"
        centered
        dark
        icons-and-text
      >
        <v-tab
          v-for="(i, index) in this.languageList"
          :key="index"
          :href="'#tab-' + i.value"
        >
          {{ i.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="languageTab">
        <v-tab-item
          v-for="(i, index) in this.languageList"
          :key="index"
          :value="`tab-${i.value}`"
          class="mt-5"
        >
          <v-text-field
            v-model="lesson.lessonData[index].languageId"
            :v-text="i.value"
            v-show="false"
          />
          <v-text-field
            v-model="lesson.lessonData[index].title"
            placeholder="Enter Lesson Title"
            outlined
            label="Lesson Title"
          >
          </v-text-field>
          <v-row>
            <v-col>
              <v-select
                outlined
                :items="lessonQuizList[index]"
                item-text="name"
                item-value="value"
                label="Lesson Quiz"
                v-model="lesson.lessonQuizId"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                outlined
                :items="lessonVideoList[index]"
                item-text="name"
                item-value="value"
                label="Lesson Video"
                v-model="lesson.lessonVideoId"
                required
              ></v-select>
            </v-col>
          </v-row>
          <div>Lesson Content {{ index + 1 }}</div>
          <CkEditorComponent
            :key="index"
            v-model.lazy="lesson.lessonData[index].contents"
            @get-text="setContents(index, $event)"
            :modelName="content1"
          />
        </v-tab-item>
      </v-tabs-items>
      <v-btn @click="goBackToLessonList" class="mt-5">Back To Lesson List</v-btn
      >&nbsp; &nbsp;<v-btn @click="createLesson" class="mt-5">Create</v-btn>
    </v-container>
  </v-main>
</template>

<script>
// @ is an alias to /src
// import { mapGetters } from "vuex";
import {
  CREATE_LESSON,
  GET_REF_TRAINING_DATA,
  FETCH_LESSON_QUIZ_LIST,
  FETCH_LESSON_VIDEO_LIST,
} from "../store/actions.type";
import CkEditorComponent from "@/components/CkEditorComponent.vue";
import { languageList } from "../common/ref_data";

export default {
  name: "CreateLesson",
  components: {
    CkEditorComponent,
  },
  data() {
    return {
      languageTab: "tab-1",
      lectures: [],
      lectureList: [],
      lessonQuizList: [],
      lessonVideoList: [],
      languageList: [],
      content1: "",
      content2: "",
      lesson: {
        lectureId: null,
        lessonVideoId: null,
        lessonQuizId: null,
        minReadingTime: 5,
        indexSort: 1,
        isImageOnly: 1,
        lessonData: [],
      },
      loading: true,
      errored: false,
    };
  },
  computed: {},
  mounted() {},
  created() {
    this.languageList = languageList;
    this.setLessons();
    this.lessonQuizList = [];
    this.lessonVideoList = [];
    // [{ name: "-- Select --", value: null }];
    this.lectureList = [{ lectureTitle: "-- Select --", id: null }];
    this.lectures = JSON.parse(localStorage.getItem("lectures"));
    this.lectureList = [{ lectureTitle: "-- Select --", id: null }];
    this.lectureList.push(...this.lectures);
    if (this.lectures == null) {
      this.$store
        .dispatch(GET_REF_TRAINING_DATA)
        .then((data) => {
          this.loading = false;
          this.lectures = data.data.lectures;
        })
        .catch(() => {
          this.loading = false;
          this.errored = true;
        })
        .finally(() => (this.loading = false));
      this.loading = false;
    } else {
      this.loading = false;
    }
    this.getLessonQuizList();
    this.getLessonVideoList();
  },
  methods: {
    setLessons() {
      console.log(this.languageList);
      for (let i = 0; i < this.languageList.length; i++) {
        this.lesson.lessonData.push({
          languageId: this.languageList[i].value,
          title: "",
          contents: ""
        });
      }
    },
    onChildUpdate(newValue) {
      this.value = newValue;
      console.log(this.value);
    },
    goBackToLessonList() {
      this.$router.push({
        name: "LessonList",
      });
    },
    setContents(index, event) {
      this.lesson.lessonData[index].contents = event;
    },
    resetLesson() {
      this.lesson = {
        lectureId: null,
        lessonVideoId: null,
        lessonQuizId: null,
        minReadingTime: 5,
        indexSort: 1,
        medias: "",
        isImageOnly: 1,
        lessonData: [],
      };
      this.setLessons();
    },
    getLessonQuizList(payload) {
      this.$store
        .dispatch(FETCH_LESSON_QUIZ_LIST, payload)
        .then((data) => {
          this.loading = false;
          if (data.length > 0) {
            for (let k = 0; k < this.languageList.length; k++) {
              this.lessonQuizList[k] = [];
              for (let i = 0; i < data.length; i++) {
                if (this.languageList[k].value == data[i].languageId) {
                  this.lessonQuizList[k].push({
                    name: data[i].externalQuizUrl,
                    value: data[i].lessonQuizId,
                  });
                }
              }
            }
          }
          console.log(this.lessonQuizList);
        })
        .catch((response) => {
          console.log(response);
          this.loading = false;
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
          this.errored = false;
        });
    },
    getLessonVideoList(payload) {
      this.$store
        .dispatch(FETCH_LESSON_VIDEO_LIST, payload)
        .then((data) => {
          this.loading = false;
          // console.log(data);
          if (data.length > 0) {
            for (let k = 0; k < this.languageList.length; k++) {
              this.lessonVideoList[k] = [];
              for (let i = 0; i < data.length; i++) {
                if (this.languageList[k].value == data[i].languageId) {
                  this.lessonVideoList[k].push({
                    name: data[i].title,
                    value: data[i].lessonVideoId,
                  });
                }
              }
            }
            console.log(this.lessonVideoList);
          }
        })
        .catch((response) => {
          console.log(response);
          this.loading = false;
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
          this.errored = false;
        });
    },
    createLesson() {
      console.log(this.lesson);
      if (
        this.lesson.lectureId != null &&
        this.lesson.lessonData[0].title &&
        this.lesson.lessonData[0].contents.length > 0 &&
        this.lesson.lessonData[1].title &&
        this.lesson.lessonData[1].contents.length > 0
      ) {
        if (this.lesson.lessonQuizId != null) {
          this.lesson.lessonQuizId = parseInt(this.lesson.lessonQuizId);
        }

        if (this.lesson.lessonVideoId != null) {
          this.lesson.lessonVideoId = parseInt(this.lesson.lessonVideoId);
        }
        this.$store
          .dispatch(CREATE_LESSON, { lessonData: this.lesson })
          .then((data) => {
            console.log(data);
            this.loading = false;
            this.resetLesson();
            this.$swal.fire({
              title: "Lesson",
              text: "Successfully created!",
              icon: "success",
              position: "top-end",
              showConfirmButton: false,
              timer: 1500,
            });
            this.$router.push({
              name: "LessonList",
            });
          })
          .catch((response) => {
            console.log(response);
            this.loading = false;
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.resetLesson();
          });
      }else{
        this.$swal.fire({
              title: "Lesson",
              text: "Please fill data in all languages",
              icon: "info",
              position: "center",
              showConfirmButton: false,
              timer: 1500,
            });
      }
    },
  },
};
</script>
